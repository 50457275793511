// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-our-story-js": () => import("./../../../src/pages/About/OurStory.js" /* webpackChunkName: "component---src-pages-about-our-story-js" */),
  "component---src-pages-about-vision-mission-js": () => import("./../../../src/pages/About/VisionMission.js" /* webpackChunkName: "component---src-pages-about-vision-mission-js" */),
  "component---src-pages-approach-asha-workers-js": () => import("./../../../src/pages/Approach/AshaWorkers.js" /* webpackChunkName: "component---src-pages-approach-asha-workers-js" */),
  "component---src-pages-approach-government-schools-js": () => import("./../../../src/pages/Approach/GovernmentSchools.js" /* webpackChunkName: "component---src-pages-approach-government-schools-js" */),
  "component---src-pages-approach-individuals-js": () => import("./../../../src/pages/Approach/Individuals.js" /* webpackChunkName: "component---src-pages-approach-individuals-js" */),
  "component---src-pages-approach-organisation-js": () => import("./../../../src/pages/Approach/Organisation.js" /* webpackChunkName: "component---src-pages-approach-organisation-js" */),
  "component---src-pages-approach-rotary-clubs-js": () => import("./../../../src/pages/Approach/RotaryClubs.js" /* webpackChunkName: "component---src-pages-approach-rotary-clubs-js" */),
  "component---src-pages-blog-details-js": () => import("./../../../src/pages/blog-details.js" /* webpackChunkName: "component---src-pages-blog-details-js" */),
  "component---src-pages-blog-js": () => import("./../../../src/pages/Blog.js" /* webpackChunkName: "component---src-pages-blog-js" */),
  "component---src-pages-donate-js": () => import("./../../../src/pages/Donate.js" /* webpackChunkName: "component---src-pages-donate-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-resources-news-js": () => import("./../../../src/pages/Resources/News.js" /* webpackChunkName: "component---src-pages-resources-news-js" */),
  "component---src-pages-resources-papers-js": () => import("./../../../src/pages/Resources/Papers.js" /* webpackChunkName: "component---src-pages-resources-papers-js" */),
  "component---src-pages-resources-video-js": () => import("./../../../src/pages/Resources/Video.js" /* webpackChunkName: "component---src-pages-resources-video-js" */),
  "component---src-pages-work-coming-soon-js": () => import("./../../../src/pages/Work/Coming-soon.js" /* webpackChunkName: "component---src-pages-work-coming-soon-js" */),
  "component---src-pages-work-products-js": () => import("./../../../src/pages/Work/Products.js" /* webpackChunkName: "component---src-pages-work-products-js" */)
}

